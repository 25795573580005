@import "scss/variables";

.favorites :global {
  .favorite-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    top: 0;
    background: rgba(242, 242, 242, 0.9);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    text-align: center;
    cursor: pointer;

    &.active {
      background: black;
    }
  }
}

.cardFavoriteButton :global {
  cursor: pointer;
  @media (min-width: 768px) {
    border-bottom-left-radius: 8px !important;
  }
}

.cardFavoriteButton:global.is-favorite {
  background-color: #181818;
  color: #ffffff;

  svg path {
    fill: #ffffff;
  }
}
